<template>
  <div class="reservation-rejected-step">
    <h1 class="title">{{$t('We are sorry about this...')}}</h1>
    <h1 class="subtitle">
      <p class="line m-0">{{$t('But your booking request')}}</p>
      <p class="line bold m-0">{{$t('has been declined')}}</p>
    </h1>
    <div class="blue-box">
      <div class="title">{{$t('Please try with another search!')}}</div>
      <p class="text">
        <span class="rf-white-color">{{$t('If you have any futher assistance')}},</span>
        <a href="https://wa.me/393518897372" target="_blank" class="d-inline contact-us rf-dark-gray-color">
          {{$t('Contact Us')}}
          <FontAwesomeIcon :icon="['fab', 'whatsapp']" color="#74776D"/>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationRejectedStep',
}
</script>

<style lang="scss" scoped>
.reservation-rejected-step {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    @include font-style($montserrat-bold, 'bold', $font-24);
    color: $dark-blue;
    text-align: center;
    margin-top: 100px;
  }
  .subtitle {
    @include font-style($montserrat, 'medium', $font-24);
    text-align: center;
    margin: 30px 0;

    .line {
      color: $dark-blue;
      text-transform: uppercase;

      &.bold {
        font-weight: $bold;
      }
    }
  }
  .blue-box {
    position: relative;
    background-color: $dark-blue;
    padding: 30px 30px 150px 30px;
    height: 100%;
    z-index: 5;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $dark-blue;
    }
    &:before {
      left: -100%;
    }
    &:after {
      left: 100%;
    }
    .title {
      @include font-style($montserrat-bold, 'bold', $font-24);
      color: $white;
    }
    .text {
      @include font-style($montserrat, 'medium', $font-14);
      text-transform: uppercase;
      text-align: center;
      margin-top: 50px;

      .whatsapp {
        width: 20px;
        height: 20px;
        border: 1px solid $dark-gray;
        border-radius: 50%;

        .whatsapp-icon {
          color: $dark-gray;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .reservation-rejected-step {
    .blue-box {
      &:before {
        display: none;
      }
    }
  }
}
</style>