<template>
  <div class="reservation-accepted-step">
    <h1 class="title">{{$t('Great news!')}}</h1>
    <div class="waves">
      <img class="w-25" src="@/assets/images/blue_roofs.png" />
    </div>
    <div class="subtitle">
      <p class="line m-0">{{$t('Your Booking Request')}}</p>
      <p class="line bold m-0">{{$t('Has Been Accepted')}}</p>
    </div>
    <div class="blue-box">
      <div class="title">{{$t('What\'s next?')}}</div>
      <div class="next-steps">
        <div class="rhombus">
          <span class="number">1</span>
        </div>
        <p class="text">{{$t('Make sure you read your contract, check that your personal information is correct and the conditions are clear.')}}</p>
        <div class="rhombus">
          <span class="number">2</span>
        </div>
        <p class="text">{{$t('Proceed to PAYMENT.')}}</p>
        <router-link to="/profile/your-roof">
          <RFButton class="mt-5" label="Got it!" background="#fff" color="#24425B"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'

export default {
  name: 'ReservationAcceptedStep',
  components: {
    RFButton,
  }
}
</script>

<style lang="scss" scoped>
.reservation-accepted-step {
  margin-top: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    @include font-style($montserrat-bold, 'bold', $font-24);
    color: $dark-blue;
    text-align: center;
  }
  .waves {
    margin: 50px 0;
    text-align: center;
  }
  .subtitle {
    text-align: center;

    .line {
      @include font-style($montserrat, 'medium', $font-24);
      color: $dark-blue;
      text-transform: uppercase;

      &.bold {
        font-weight: $bold;
      }
    }
  }
  .blue-box {
    background-color: $dark-blue;
    position: relative;
    padding: 30px 30px 150px 30px;
    margin-top: 30px;
    height: 100%;
    z-index: 5;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $dark-blue;
    }
    &:before {
      left: -100%;
    }
    &:after {
      left: 100%;
    }
    .title {
      @include font-style($montserrat, 'semibold', $font-18);
      color: $white;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .next-steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      .rhombus {
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        border: 1px solid $dark-gray;
        color: $dark-gray;
        display: flex;
        align-items: center;
        justify-content: center;

        .number {
          transform: rotate(-45deg);
        }
      }
      .text {
        @include font-style($hind, 'light', $font-14);
        color: $white;
        margin: 20px 0;
        max-width: 60%;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .reservation-accepted-step {
    margin-top: 0;

    .blue-box {
      &:before {
        display: none;
      }
    }
  }
}
</style>