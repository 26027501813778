import { render, staticRenderFns } from "./reservation-rejected-step.vue?vue&type=template&id=25a1d5e1&scoped=true"
import script from "./reservation-rejected-step.vue?vue&type=script&lang=js"
export * from "./reservation-rejected-step.vue?vue&type=script&lang=js"
import style0 from "./reservation-rejected-step.vue?vue&type=style&index=0&id=25a1d5e1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a1d5e1",
  null
  
)

export default component.exports