<template>
  <div class="registered-step">
    <h1 class="title rf-blue-color">Ciao!</h1>
    <h1 class="subtitle rf-blue-color">{{$t('Nice to have you around')}}</h1>
    <div class="waves text-center">
      <img class="w-25" src="@/assets/images/blue_roofs.png">
    </div>
    <p class="text">{{$t('Just one small step to conclude your booking...')}}</p>
    <div class="text-box">
      <p class="text in-box">
        {{$t('Please complete your profile information and upload your documents so we can complete the review.')}}
      </p>
    </div>
    <div class="blue-box">
      <h1 class="title">{{$t('We\'ll get in touch with you soon!')}}</h1>
      <div class="d-flex justify-content-center">
        <router-link to="/profile/your-info">
          <RFButton class="mt-5" label="Got it" background="#fff" color="#24425B"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'

export default {
  name: 'RegisteredStep',
  components: {
    RFButton,
  }
}
</script>

<style lang="scss" scoped>
.registered-step {
  margin: 30px 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    @include font-style($montserrat-bold, 'bold', $font-30);
    text-align: center;
  }
  .subtitle {
    @include font-style($montserrat, 'medium', $font-30);
    text-align: center;
  }
  .waves {
    margin: 70px 0;
  }
  .text {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
    text-align: center;

    &.in-box {
      @include font-style($montserrat-bold, 'bold', $font-16);
      margin: 0;
    }
  }
  .text-box {
    padding: 20px;
    border: 2px solid $gray;
    margin: 0 auto;
    width: 75%;
    margin-bottom: 30px;
  }
  .blue-box {
    height: 100%;
    background-color: $dark-blue;
    position: relative;
    padding: 30px;
    z-index: 5;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $dark-blue;
    }
    &:before {
      left: -100%;
    }
    &:after {
      left: 100%;
    }
    .title {
      @include font-style($montserrat, 'semibold', $font-20);
      color: $white;
      text-transform: uppercase;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .registered-step {
    margin-top: 0;

    .blue-box {
      &:before {
        display: none;
      }
    }
  }
}
</style>