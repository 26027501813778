<template>
  <div class="user-declined-step">
    Your documents are declined. Please upload them again.
  </div>
</template>

<script>
export default {
  name: 'UserDeclinedStep',
}
</script>

<style lang="scss" scoped>
</style>