<template>
  <div class="profile-page">
    <b-container>
      <b-row>
        <b-col class="menu pb-4 pr-lg-5" cols="12" lg="5">
          <div class="profile-menu">
            <div class="welcome">
              {{ $t('Welcome') }}
              <div class="user" v-if="user">{{user.firstname}}!</div>
            </div>
            <router-link v-for="(section, index) in sections" :key="index"
            :to="section.to">
              <RFButton
                :label="section.label"
                :background="includedOnPath(section.to) ? '#24425B' : '#DADBD7'"
                full-width
                :color="includedOnPath(section.to) ? '#fff' : '#24425B'"
                :border-color="includedOnPath(section.to) ? 'transparent' : '#24425B'"
                class="mt-3"
              />
            </router-link>
          </div>
        </b-col>
        <b-col cols="12" lg="7" class="content pl-lg-0">
          <template v-if="isProfileHome">
            <RegisteredStep v-if="status === userStatus.REGISTERED"/>
            <CheckDocumentsStep v-else-if="status === userStatus.CHECK_DOCUMENT"/>
            <UserDeclinedStep v-else-if="status === userStatus.REJECTED"/>
            <template v-else-if="status === userStatus.VERIFIED">
              <ReservationAcceptedStep v-if="reservationStatus === enumReservationStatus.ACCEPTED"/>
              <ReservationRejectedStep v-else-if="reservationStatus === enumReservationStatus.REJECTED"/>
            </template>
          </template>
          <router-view v-else/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import { store } from '@/stores'

import RegisteredStep from '@/pages/profile/steps/registered-step'
import CheckDocumentsStep from '@/pages/profile/steps/check-documents-step'
import UserDeclinedStep from '@/pages/profile/steps/user-declined-step'
import ReservationAcceptedStep from '@/pages/profile/steps/reservation-accepted-step'
import ReservationRejectedStep from '@/pages/profile/steps/reservation-rejected-step'

import { reservationStatus as enumReservationStatus, roles, userStatus } from '@/enums'
import { isAdmin } from '@/helpers/functions'

export default {
  components: {
    RFButton,
    RegisteredStep,
    CheckDocumentsStep,
    UserDeclinedStep,
    ReservationAcceptedStep,
    ReservationRejectedStep,
  },
  name: 'ProfilePage',
  beforeRouteEnter(to, from, next) {
    if (store.state.userStore.loggedIn) {
      const userRoles = store.state.userStore.user.roles

      if (isAdmin(userRoles)) {
        next({ name: 'admin' })
      } else {
        next()
      }
    } else {
      next({ name: 'homepage' })
    }
  },
  data() {
    return {
      userStatus,
      enumReservationStatus,

      sections: [
        { label: 'Your Info', to: '/profile/your-info' },
        { label: 'Your Roof', to: '/profile/your-roof', },
      ]
    }
  },
  computed: {
    status() {
      return this.user ? this.user.status : null
    },
    reservationStatus() {
      return this.user.reservations && this.user.reservations.length ? this.user.reservations[0].status : null // TBD
    },
    isProfileHome() {
      return this.$route.name === 'profile'
    },
    user() {
      return this.$store.state.userStore.user
    }
  },
  methods: {
    includedOnPath(path) {
      return this.$route.path.includes(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-page {
  background-color: $gray;

  .menu {
    border-right: none;
    padding-top: 100px;

    .welcome {
      @include font-style($montserrat, 'medium', $font-30);
      color: #24425B;
      margin-bottom: 30px;
      line-height: 35px;

      .user {
        font-weight: 600;
      }
    }
  }
  .content {
    background-color: $white;
    // height: 100%;
    // min-height: 100vh;

    position: relative;
    z-index: 5;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $white;
    }
    &:before {
      left: -100%;
    }
    &:after {
      left: 100%;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .profile-page {
    position: relative;

    .menu {
      border-right: 1px solid $gray;
      padding-top: 150px;
      position: relative;

      .profile-menu {
        position: fixed;
      }
    }
    .content {
      min-height: 100vh;
      background-color: $white;
      position: relative;
      padding-top: 150px;

      &:before {
        display: none;
      }
    }
  }
}
</style>
