<template>
  <div class="check-documents-step">
    <div class="title-container">
      <h1 class="title">{{$t('Your documents have been submitted successfully.')}}</h1>
    </div>
    <div class="blue-box">
      <h1 class="title">{{$t('We\'ll let you know as soon as possible when the review is concluded')}}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckDocumentsStep',
}
</script>

<style lang="scss" scoped>
.check-documents-step {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title-container {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 50px 0;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-24);
      color: $dark-blue;
      width: 60%;
    }
  }
  .blue-box {
    background-color: $dark-blue;
    position: relative;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    z-index: 5;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $dark-blue;
    }
    &:before {
      left: -100%;
    }
    &:after {
      left: 100%;
    }
    .title {
      @include font-style($montserrat, 'medium', $font-20);
      color: $white;
      text-transform: uppercase;
      width: 70%;
      text-align: center;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .check-documents-step {
    .title-container {
      margin: 150px 0;
    }
    .blue-box {
      padding: 30px 30px 150px 30px;

      &:before {
        display: none;
      }
    }
  }
}
</style>